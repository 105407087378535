import React, {FunctionComponent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';

import {loadArticleAction} from 'redux/content/contentAsyncActions';
import {IStore} from 'redux/interface';

import {BotNameMode} from '@wholesalechange/chatcomponent';
import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';
import BotIcon from 'assets/image/user-settings/BotDefaultAvatar.png';
import {useLoadFile} from 'utils/hooks/useLoadFile';

import {XpeditionList} from './xpedition/XpeditionList';
import {BotContent} from './BotContent';
import {BotActionType, BotChat as Chat, BotMode, MessageType, BotType, IBotAction} from '@wholesalechange/chatcomponent';
import {
  contentAsyncActions,
  dialogueAsyncActions,
  getArticle,
  getDialogueParticipant,
  getXpeditions
} from '../../redux/pageAsyncActions';
import {useDictionary} from '../../utils/hooks/useDictionary';
import {useChatBotNew} from '../../utils/hooks/useChatBotNew';
import Loader from '../loader';

import './styles.scss';
import {useNavigate} from 'react-router-dom';
import NotFoundInfo from '../not-found-info';
import CrossroadImg from '../../assets/image/library/skill-crossroad.svg';
import {closeArticle} from '../../redux/content/actions';
import {useUploadImage} from '../../utils/hooks/useUploadImage';

interface IBotChat {
  mode: BotType
  dialogueId?: number
  journalMode?: boolean
  title: string
}

export const BotChat: FunctionComponent<IBotChat> = ({mode, dialogueId, journalMode, title}: IBotChat) => {
  const intl = useIntl();

  const [isShowContent, setIsShowContent] = useState(true);
  const [contentTitle, setContentTitle] = useState<string>('');
  const [toggleChatTab, setToggleChatTab] = useState(false);

  function getBotMode(type: BotType): BotMode {
    return type && type === BotType.Xpedition ? BotMode.NoHistory : BotMode.FullHistory;
  }

  const botMode = getBotMode(mode);
  const chatBot = useChatBotNew(mode, botMode);
  const history = useNavigate();

  const {
    status: {loading},
    content: {article},
    auth: {userProfile},
    dialogue: {participant, xpeditions}
  } = useSelector((state: IStore) => state);

  const dictionary = useDictionary();

  const dispatch = useDispatch();
  const {getFileUrl, getFileUrlAsync, removeFileAsync} = useLoadFile();
  const {uploadImageUrlAsync} = useUploadImage();

  useEffect(() => {
    if (chatBot.currentDialog && userProfile) {
      dispatch(dialogueAsyncActions[getDialogueParticipant](userProfile.id, chatBot.currentDialog.id));
    }
  }, [userProfile, chatBot.currentDialog]);

  useEffect(() => {
    if (mode === BotType.Xpedition && dialogueId) {
      chatBot.xpedition.setXpeditionDialogue(dialogueId);
    }
  }, [dialogueId]);

  useEffect(() => {
    if (article && article.title) {
      setContentTitle(article.title);
    } else if (chatBot.botAnswer && chatBot.botAnswer.length) {
      const pictures = chatBot.botAnswer.filter(item => {
        return !item.contentId && item.picture;
      });

      if (pictures && pictures.length && pictures[0].text) {
        setContentTitle(pictures[0].text);
      } else {
        setContentTitle('');
      }
    } else {
      setContentTitle('');
    }
  }, [chatBot.botAnswer, article]);

  const pushUserMessage = (text: string) => {
    if (chatBot.currentDialog && participant) {
      chatBot.sendMessage(MessageType.Text, participant, {text});
    }
  };

  const handleSendMessage = (message: string) => {
    pushUserMessage(message.trim());
  };

  async function loadMore() {
    if (chatBot.currentDialog && !chatBot.isLoading) {
      await chatBot.dialogueMethods.fetchMore();
    }
  }

  function handleShowArticle(code: string) {
    setIsShowContent(true);
    if (article && article.id.toString() === code) {
      return;
    }
    dispatch(contentAsyncActions[getArticle](code));
  }

  function handleCloseArticle() {
    if (article) {
      dispatch(closeArticle());
      setIsShowContent(false);
      setContentTitle('');
    }
  }

  function handleCloseContent() {
    setToggleChatTab(true);
    setTimeout(() => {
      setToggleChatTab(false);
    }, 1000);
  }

  function handleShowExternalOverlay() {
    if (article) {
      dispatch(closeArticle());
    }
    setIsShowContent(false);
  }

  useEffect(() => {
    if (mode === BotType.XpeditionList) {
      dispatch(dialogueAsyncActions[getXpeditions]());
    } else if (mode !== BotType.Xpedition && userProfile?.greetingContentId) {
      dispatch(loadArticleAction(userProfile.greetingContentId.toString()));
      setIsShowContent(true);
    }
  }, [userProfile]);

  function handleHelp() {
    pushUserMessage('Help');
  }

  function handleSendAction(actionButton: IBotAction) {
    if (!chatBot.currentDialog || !participant) {
      return;
    }
    if (!actionButton) {
      return;
    }
    const actionBody = JSON.parse(actionButton.actionBody);

    switch (actionButton.actionType) {
      case BotActionType.ContentView:
        dispatch(loadArticleAction(actionBody.addActionBody.contentInfo.id));
        setIsShowContent(true);
        chatBot.sendMessage(MessageType.Action, participant, {
          button: {
            actionType: actionButton.actionType,
            actionBody: actionButton.actionBody,
            actionUrl: actionButton.actionUrl,
            serialNumber: actionButton.serialNumber,
            text: actionButton.text
          }
        });
        break;
      case BotActionType.OpenURL:
      case BotActionType.ReturnValue:
      case BotActionType.Schedule:
      case BotActionType.Image:
      case BotActionType.Overlay:
        chatBot.sendMessage(MessageType.Action, participant, {
          button: {
            actionType: actionButton.actionType,
            actionBody: actionButton.actionBody,
            actionUrl: actionButton.actionUrl,
            serialNumber: actionButton.serialNumber,
            text: actionButton.text
          }
        });
        break;
      default:
        break;
    }
  }

  function handleStartXpedition(botId: number) {
    history(`/xpedition/${botId}`);
  }

  async function handleRemoveDialog(dialogId: number) {
    await chatBot.dialogueMethods.removeDialog(dialogId);
    dispatch(dialogueAsyncActions[getXpeditions]());
  }

  function getBotAvatar() {
    return userProfile?.dialogAvatarId ? getFileUrl(userProfile.dialogAvatarId) : BotIcon;
  }

  function renderButtons() {
    return mode !== BotType.Xpedition ? <GritxButton key='help'
      title={intl.formatMessage({
        id: 'gritx.chat.helpButton',
        defaultMessage: 'Help'
      })}
      variant={ButtonVariant.Outline}
      className="dialogue-chat__help-btn"
      onClick={handleHelp}
    /> : <></>;
  }

  function getShowContentInRightPanel() {
    return mode === BotType.Xpedition || chatBot.showContentInRightPanel;
  }

  if (loading.includes(getXpeditions)) {
    return <Loader nested/>;
  }

  if (mode === BotType.XpeditionList) {
    if (xpeditions.length === 0) {
      return <NotFoundInfo
        title={intl.formatMessage({
          id: 'gritx.xpedition.notFound',
          defaultMessage: 'No Xpeditions Added Yet'
        })}
        image={CrossroadImg}
      />;
    }

    return <XpeditionList
      onStart={handleStartXpedition}
      onRemove={handleRemoveDialog}
    />;
  }

  if (chatBot.dialogIsNotSet) {
    return <NotFoundInfo
      title={intl.formatMessage({
        id: 'gritx.chat.notFound',
        defaultMessage: 'Dialogue has not been added yet'
      })}
      image={CrossroadImg}
    />;
  }

  if (chatBot.currentDialog) {
    return <>
      <div className="chat__container">
        {userProfile && <Chat isLoading={chatBot.isLoading}
          isTyping={chatBot.isTyping}
          isSending={chatBot.isSending}
          chatAvatar={getBotAvatar()}
          toolbarButtons={[renderButtons()]}
          messages={chatBot.messages || []}
          onSend={handleSendMessage}
          onSendAction={handleSendAction}
          currentUser={userProfile}
          onLoadMore={loadMore}
          onShowArticle={handleShowArticle}
          onCloseArticle={handleCloseArticle}
          onShowExternalOverlay={handleShowExternalOverlay}
          dictionary={dictionary}
          botMode={botMode}
          isConnected={chatBot.isConnected}
          botNameMode={BotNameMode.PARTICIPANT}
          getFileUrl={getFileUrlAsync}
          removeFile={removeFileAsync}
          uploadImage={uploadImageUrlAsync}
          ttsSettings={{
            azureSubscriptionKey: process.env.REACT_APP_AZURE_SUBSCRIPTION_KEY || '',
            azureRegion: process.env.REACT_APP_AZURE_REGION || ''
          }}
          showContentInRightPanel={getShowContentInRightPanel()}
          contentElement={isShowContent ? <BotContent botAnswer={chatBot.botAnswer} onCloseContent={handleCloseContent}/> : null}
          tabsEnabled={false}
          dialogueTitle={title}
          contentTitle={contentTitle}
          toggleChatTab={toggleChatTab}
          journalMode={journalMode}
        />}
      </div>
    </>;
  }

  return <></>;
};
